<template>
  <div class="footer-info">
    <div class="info-title">
      <div class="title">
        <span>Mr|小斌的博客</span>
      </div>
    </div>
    <div class="footer-list">
      <a href="https://github.com/xiaobinxxx" target="_blank">gitHub</a>
      <a href="https://github.com/xiaobinxxx/996-Music" target="_blank">996Music</a>
      <a href="https://github.com/xiaobinxxx/996-Popup" target="_blank">996弹出层</a>
      <a href="http://beian.miit.gov.cn" target="_blank">黑ICP备19006880号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
}
</script>

<style scoped lang="scss">
.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: $bg-color-inverse;

  .info-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;

    .title {
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        color: $text-color;
      }
    }
  }

  .footer-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;

    span {
      color: $text-color-assist;
      font-size: 14px;
    }

    a {
      color: $text-color-assist;
      font-size: 14px;
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid $text-color-assist;
      text-decoration: underline;
    }
  }
}
</style>
