import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path:"/",
    name:"home",
    title: "首页",
    component:() => import("@/pages/home/home.vue")
  },{
    path:"/article",
    name:"article",
    title: "文章",
    component:() => import("@/pages/article/article.vue")
  },{
    path:"/works",
    name:"works",
    title: "作品",
    component:() => import("@/pages/works/works.vue")
  },{
    path:"/OpenSource",
    name:"OpenSource",
    title: "开源项目",
    component:() => import("@/pages/OpenSource/OpenSource.vue")
  },{
    path:"/LeaveMessage",
    name:"LeaveMessage",
    title: "留言",
    component:() => import("@/pages/LeaveMessage/LeaveMessage.vue")
  },{
    path:"/AboutMe",
    name:"AboutMe",
    title: "关于我",
    component:() => import("@/pages/AboutMe/AboutMe.vue")
  },{
    path:"/ArticleDetails",
    name:"ArticleDetails",
    title: "文章详情",
    component:() => import("@/pages/ArticleDetails/ArticleDetails.vue")
  },{
    path:"/SearchResult",
    name:"SearchResult",
    title: "搜索结果",
    component:() => import("@/pages/SearchResult/SearchResult.vue")
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      window.scrollTo(savedPosition.left,savedPosition.top);
    } else {
      window.scrollTo(0,0);
    }
  },
  routes
});
// router.afterEach(() => {
//   window.scrollTo(0,0);
// })
export default router;
//在main.js中进行引入，并进行挂载注册到全局上
