<template>
  <router-view></router-view>
  <Footer></Footer>
</template>

<script>

import Footer from "./components/footer/footer";
export default {
  name: 'App',
  components: {Footer},
}
</script>

<style lang="scss">
  @import "assets/css/public.css";
  @import "assets/css/FontStyle.css";
  body{
    background-color: #eef1f6;
    padding-bottom: 100px;
  }
</style>
