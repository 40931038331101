import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

// 路由挂载
import router from "./router";
app.use(router)
// ui库
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)

// 工具
import util from "./common/util";
app.config.globalProperties.util = util;

app.mount('#app')
